import React from 'react'
import {Layout} from "../components/Layout";
import {Col} from "react-bootstrap";
import {DarkOnLightRow} from "../components/Rows";
import {RowClassesAlignCenter} from "../components/RowStyles";
import NavbarSpacer from "../components/NavbarSpacer";
import {ContactForm} from "../components/ContactForm";

export default function Careers(props) {
  return (
    <Layout className="navbar-light">
      <DarkOnLightRow className={RowClassesAlignCenter}>
        <NavbarSpacer/>
        <Col md={{span: 3}}>
          <h3>Contact Us</h3>
        </Col>
        <Col md={{span: 9}} className="p-0 p-lg-4">
          <ContactForm />
        </Col>
      </DarkOnLightRow>
    </Layout>
  )
}
