import React from 'react';
import {useForm, ValidationError} from '@formspree/react';
import {Button, Form} from "react-bootstrap";
import {FormColoredInputs} from "./FormColoredInputs";
export function ContactForm({className}) {
  const [state, handleSubmit] = useForm("mdoykrgz");
  if (state.succeeded) {
    return <h3>Your message was sent.</h3>;
  }
  if (state.errors && state.errors.length > 0) {
    return (<><h3>Your contact form has errors:</h3>
      {state.errors.map(msg => (<p>{msg.message}</p>))}
    </>)
  }
  return (
    <FormColoredInputs onSubmit={handleSubmit} className={className}>
      <Form.Group controlId="email">
        <Form.Label>Email Address</Form.Label>
        <Form.Control name="email" type="email" placeholder="address@example.com"/>
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
      </Form.Group>
      <br/>
      <Form.Group controlId="message">
        <Form.Label>Message</Form.Label>
        <Form.Control name="message" as="textarea" rows={3}/>
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </Form.Group>
      <br/>
      <Form.Group>
        <Button variant="primary" type="submit" disabled={state.submitting}>Submit</Button>
      </Form.Group>
    </FormColoredInputs>
  );
}
